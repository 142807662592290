import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Image from "gatsby-plugin-sanity-image";
import { slugify, artistname } from "../../lib/utils";

const Container = styled.div`
  max-width: calc(100vw - 2rem);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 100%;
    max-width: 100%;
    aspect-ratio: 2480/3508;
  }
`;

const ArtistName = styled.h3`
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  margin-top: 16px;
`;

const Location = styled.p`
  font-weight: bold;
`;

const Dates = styled.p``;

const ImageContainer = styled.div`
  @media (max-width: 880px) {
    max-width: 75%;
  }
`;

const Tickets = styled.a`
  font-weight: bold;
  text-decoration: none;
  font-family: Catamaran, sans-serif;
`;

const LiveShow = (props) => {
  const { show } = props;

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const artist_link = "/artists/" + slugify(show.name);

  const suffix = (i) => {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  };

  if (show && show.title) {
    const start = new Date(show.start_date * 1000);
    const end = new Date(show.end_date * 1000);

    // const start_day = ("0" + start.getDate()).slice(-2);
    // const end_day = ("0" + end.getDate()).slice(-2);
    //
    const start_day = start.getDate();
    const end_day = end.getDate();

    // const start_month = ("0" + (start.getMonth() + 1)).slice(-2)
    // const end_month = ("0" + (end.getMonth() + 1)).slice(-2)

    const start_month = monthNames[start.getMonth()];
    const end_month = monthNames[end.getMonth()];

    const start_datum = `${suffix(start_day)} ${start_month} ${start.getFullYear()}`;
    const end_datum = `${suffix(end_day)} ${end_month} ${end.getFullYear()}`;

    const pic = show.poster ? show.poster : show.image;

    const extras = show?.touring_with?.map((t, index) => {
      const extra_name = artistname(t);
      const extra_url = `/artists/${slugify(t.first_name + " " + t.surname)}`;
      return (
        <React.Fragment key={t.id}>
          {index > 0 && ", "}
          <Link to={extra_url}>{extra_name}</Link>
        </React.Fragment>
      );
    });

    let showname;

    if (show?.touring_with?.length > 0) {
      showname = (
        <>
          <Link to={artist_link}>{show.name}</Link>
          {extras.length > 0 && <>, </>}
          {extras}
        </>
      );
    } else {
      showname = <Link to={artist_link}>{show.name}</Link>;
    }

    return (
      <Container>
        <ImageContainer>
          <Image
            {...pic}
            alt={show.title}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "right 30%",
            }}
          />
        </ImageContainer>
        <ArtistName>{show.title}</ArtistName>
        <Location>
          <Link to={artist_link}>{showname}</Link> - {show.location}
        </Location>
        <Dates>
          {start_datum} - {end_datum}
        </Dates>
        {show.url && (
          <Tickets target="_blank" href={show.url}>
            Get Tickets
          </Tickets>
        )}
      </Container>
    );
  }
};

export default LiveShow;
