import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Title from "../components/title";
import Grid from "../components/grid";
import LiveShow from "../components/live_show";
import PageSEO from "../components/page_seo";

const PageLiveShows = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityArtist(sort: { fields: tours___start_date }) {
        edges {
          node {
            first_name
            surname
            tours {
              touring_with {
                first_name
                surname
                id
              }
              end_date(formatString: "X")
              location
              start_date(formatString: "X")
              tickets_url
              title
              tour_type
              poster {
                ...ImageWithPreview
              }
            }
            headshot {
              ...ImageWithPreview
            }
          }
        }
      }
    }
  `);

  const artists_with_shows = data.allSanityArtist.edges.filter(
    (s) => s.node.tours.length > 0,
  );
  const now = Date.now();

  const current_shows = [];
  const future_shows = [];

  artists_with_shows.map((a) => {
    const filtered_current_shows = a.node.tours.filter(
      (t) =>
        t.end_date * 1000 > now &&
        t.start_date * 1000 < now &&
        t.tour_type !== "edinburgh",
    );
    const filtered_future_shows = a.node.tours.filter(
      (t) => t.start_date * 1000 > now && t.tour_type !== "edinburgh",
    );

    filtered_current_shows.map((s) => {
      current_shows.push({
        title: s.title,
        touring_with: s.touring_with,
        location: s.location,
        start_date: s.start_date,
        end_date: s.end_date,
        url: s.tickets_url,
        name: `${a.node.first_name} ${a.node.surname}`,
        image: a.node.headshot,
        poster: s.poster,
      });
    });

    filtered_future_shows.map((s) => {
      future_shows.push({
        title: s.title,
        touring_with: s.touring_with,
        location: s.location,
        start_date: s.start_date,
        end_date: s.end_date,
        url: s.tickets_url,
        name: `${a.node.first_name} ${a.node.surname}`,
        image: a.node.headshot,
        poster: s.poster,
      });
    });
  });

  current_shows.filter(Boolean);
  future_shows.filter(Boolean);

  return (
    <>
      <PageSEO path="/ontour" />
      <Title>Live Shows</Title>
      <Grid maxwidth="1240px" colgap="130px" rowgap="53px" mt="52px">
        {current_shows.map((c, index) => {
          return <LiveShow key={index} show={c} />;
        })}
      </Grid>
      <Title>Coming Up</Title>
      <Grid maxwidth="1240px" colgap="130px" rowgap="53px" mt="52px">
        {future_shows.map((c, index) => {
          return <LiveShow key={index} show={c} />;
        })}
      </Grid>
    </>
  );
};

export default PageLiveShows;
